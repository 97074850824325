import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import palette from "src/theme/palette";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import GetAppIcon from "@mui/icons-material/GetApp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Stack, styled } from "@mui/system";
import typography from "src/theme/typography";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { decrementAwaitingApiCounter, incrementAwaitingApiCounter } from "../../../src/features/ui/uiState";
import { useAppDispatch, useAppSelector } from "./../../../src/hooks/hooks";
import { getPlansInfo } from "src/features/plans/plansState";
import { getUserInfo, updateUserInfo } from "./../../../src/features/user/userState";
import { GetBlogList, UpdateBlogList, deleteBlogList, toggleArchiveAPI } from 'src/features/blogs/blogsapi';
import { retrieveLogoUrl } from "src/features/config/configApi";

const tableBgColor = palette.primary.lighter;


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.black,
  textWrap: "nowrap",
  fontFamily: typography.fontFamilyRegular,
  cursor: `url('/assets/cursorImage.png'), pointer`,
}));
const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  backgroundColor: tableBgColor,
  color: theme.palette.common.black,
  "& .MuiInputBase-root": {
    border: "1px solid #757575",
    borderRadius: 4,
    width: "58px",
  },
  "& .MuiPaper-root": {
    color: theme.palette.common.black,
  },
}));
const StyledTableHead = styled(TableHead)(({ theme }) => ({
  "& .MuiTableRow-head > .MuiTableCell-head": {
    color: theme.palette.common.black,
    backgroundColor: "#F4F6F8",
  },
  "& .MuiTableRow-head > .MuiTableCell-head> .MuiTableSortLabel-root:hover":
  {
    color: theme.palette.common.black,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "#F4F6F8",
  },

}));

const StyledTableBody = styled(TableBody)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: tableBgColor,
  fontFamily: typography.fontFamilyRegular,
}));

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [draggedRowId, setDraggedRowId] = useState(null);
  const [reorderedRows, setReorderedRows] = useState([]);
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [modifiedRows, setModifiedRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const handleMenuClick = (event, blog) => {
    setAnchorEl(event.currentTarget);
    setSelectedBlog(blog);
  };

  const [modal, setModal] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const marketingSiteURL = process.env.REACT_APP_MARKETING_SITE_URL

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedBlog(null);
  };

  const handleView = () => {
    const url = `${marketingSiteURL}${encodeURIComponent(selectedBlog?.blogTitle)}`;
    window.open(url, '_blank');
    handleMenuClose();
  };


  const userInfo = useAppSelector(getUserInfo);

  const plansInfo = useAppSelector(getPlansInfo);
  const enhancedUserInfo = {
    accessToken: userInfo?.accessToken,
  };
  const getBloglistData = async () => {
    dispatch(incrementAwaitingApiCounter());
    try {
      const blogResponse = await GetBlogList(enhancedUserInfo);

      if (
        blogResponse?.statusCode === 200) {
        const blogData = blogResponse.data;
        setBlogs(blogData);
        dispatch(decrementAwaitingApiCounter());
      }
    } catch (error) {
      console.error("Error fetching blog info:", error);
      dispatch(decrementAwaitingApiCounter());
    }
  };


  const getRowClassName = (id) => {
    return highlightedRows.includes(id) ? 'highlighted-row' : '';
  };
  useEffect(() => {
    getBloglistData();
  }, []);
  const handleDragStart = (id) => {
    setDraggedRowId(id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSaveOrder = async () => {

    dispatch(incrementAwaitingApiCounter());

    try {
      const startOrder = blogs.findIndex(
        (blog) => blog?._id === modifiedRows[0]?._id
      ) + 1;

      const updatedOrder = {
        ids: modifiedRows?.map((row, index) => ({
          id: row?._id,
          order: startOrder + index,
        })),
      };


      const updateBlogResponse = await UpdateBlogList(updatedOrder, enhancedUserInfo);

      if (updateBlogResponse?.status === 200) {
        dispatch(incrementAwaitingApiCounter());
        const updatedBlogData = updateBlogResponse?.data;
        setModifiedRows([]);
        setReorderedRows([])
        setHighlightedRows([]);
        await getBloglistData();
        dispatch(decrementAwaitingApiCounter());
      } else {
        console.error('Failed to update blog order. API Response:', updateBlogResponse);
      }
    } catch (error) {
      console.error('Error while saving order:', error);
    } finally {
      dispatch(decrementAwaitingApiCounter());
    }
  };


  const handleDrop = async (id) => {
    try {
      let newRows;
      setBlogs((prevRows) => {
        const draggedRow = prevRows.find((row) => row?._id === draggedRowId);
        const targetIndex = prevRows.findIndex((row) => row?._id === id);
        const draggedIndex = prevRows.findIndex((row) => row?._id === draggedRowId);

        newRows = [...prevRows];
        newRows.splice(draggedIndex, 1);
        newRows.splice(targetIndex, 0, draggedRow);
        console.log("New Rows after drag-and-drop:", newRows);

        const affectedRows = newRows.slice(
          Math.min(draggedIndex, targetIndex),
          Math.max(draggedIndex, targetIndex) + 1
        );
        setReorderedRows(newRows);
        setHighlightedRows((prev) => [...new Set([...prev, draggedRow._id])]);
        setModifiedRows(affectedRows);
        return newRows;
      });

      setDraggedRowId(null);
    } catch (error) {
      console.error('Error during drag-and-drop operation:', error);
    }
  };
  const handleSelectAll = (event) => {
    if (event?.target?.checked) {
      setSelected(blogs?.map((blog) => blog?._id));
    } else {
      setSelected([]);
    }
  };

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected?.includes(id)
        ? prevSelected?.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };



  const handleDelete = async () => {
    try {
      dispatch(incrementAwaitingApiCounter());
      const dataToDelete = { blog_id: selected };

      console.log("Data sent to API:", dataToDelete);

      const deleteResponse = await deleteBlogList(dataToDelete, enhancedUserInfo);
      if (deleteResponse?.statusCode === 200) {
        setBlogs((prevBlogs) => prevBlogs?.filter((blog) => !selected.includes(blog?._id)));
        setModal({
          open: true,
          message: `${selected?.length > 1 ? "Blogs" : "Blog"} deleted successfully!`,
          severity: 'success',
        });
        await getBloglistData();
      } else {
        console.error("Failed to delete blogs. API Response:", deleteResponse);
      }
      setSelected([]);
    } catch (error) {
      console.error("Error while deleting blogs:", error);
    } finally {
      dispatch(decrementAwaitingApiCounter());
      setOpenDialog(false);
    }
    setAnchorEl(null);
  };

  const handleSearch = (event) => {
    setSearchText(event?.target?.value);

  };
  
  const filteredBlogs = useMemo(() =>
    blogs?.filter((blog) =>
      blog?.blogTitle?.toLowerCase()?.includes(searchText?.toLowerCase())
    ),
    [blogs, searchText]
  );
const currentPageBlogs = useMemo(() => {
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  return blogs.slice(startIndex, endIndex); 
}, [blogs, page, rowsPerPage]);

const filteredCurrentPageBlogs = useMemo(() => {
  return currentPageBlogs.filter((blog) =>
    blog?.blogTitle?.toLowerCase().includes(searchText.toLowerCase())
  );
}, [currentPageBlogs, searchText]);



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const handleAddBlog = () => {
    dispatch(incrementAwaitingApiCounter());
    navigate("/dashboard/blogs/manage-blogs")
    dispatch(decrementAwaitingApiCounter());

  };

  const handleDeleteBlog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = new Date(timestamp * 1000); 
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };


  const renderStatus = (status) => {
    let color = "";
    if (status === "published") color = "#1D9210";
    if (status === "draft") color = "#DC9510";
    if (status === "archive") color = "#E50909";
    if (status === "unarchive") color = "#256FA5";

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {status ? <> <FiberManualRecordIcon sx={{ color, fontSize: 12, marginRight: 1 }} />
          <Typography sx={{ color, fontFamily: 'LactoRegular' }}>{status.charAt(0).toUpperCase() + status.slice(1)}</Typography>
        </> : <Typography sx={{ color, fontFamily: 'LactoRegular' }}>-</Typography>}
      </Box>
    );
  };
  const handleActionClick = async (blog, userInfo) => {
    dispatch(incrementAwaitingApiCounter());

    try {

      const isArchived = blog?.status === "archive";
      const newAction = isArchived ? "unarchive" : "archive";
      const requestBody = {
        status: newAction,
      };
      const payload = { ...enhancedUserInfo, blog_id: blog?._id }
      const response = await toggleArchiveAPI(requestBody, payload);
      if (response?.status === 200) {
        await getBloglistData();

      } else {
        console.error("Failed to update blog action. API Response:", response);
        dispatch(decrementAwaitingApiCounter());

      }
    } catch (error) {
      console.error("Error while updating action:", error);
      dispatch(decrementAwaitingApiCounter());

    }
    dispatch(decrementAwaitingApiCounter());
    setAnchorEl(null);

  };
  const handleModalClose = () => {
    setModal({ ...modal, open: false });
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Toolbar sx={{ display: "flex", justifyContent: "space-between", mb: 2 }} className={'nopadding'} >
        <TextField
          sx={{
            boxShadow: "none",
            backgroundColor: "common.white",
            fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
            lineHeight: "24px",
            "& :nth-of-type(1)": {
              fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
            },
            "& > div > input": {
              boxSizing: "border-box",
              color: "primary.main",
              height: { xs: 36, lg: 40 },
            },
            "& > div": {
              padding: 0,
            },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: "primary.dark",
              },
              "&.Mui-focused fieldset": {
                borderColor: "primary.dark",
              },
            },
          }}
          variant="outlined"
          name="blog search"
          size="small"
          value={searchText}
          placeholder="Search blog"
          onChange={handleSearch}
          type="text"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    boxShadow: "none",
                    backgroundColor: "secondary.main",
                    alignContent: "center",
                    justifyContent: "center",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    height: { xs: 36, lg: 40 },
                    width: { sm: 50, lg: 60 },
                    minWidth: 30,
                    "& .MuiButton-startIcon": {
                      margin: "0",
                    },
                  }}
                  startIcon={
                    <InputAdornment position="start">
                      <SearchIcon sx={{
                        width:
                        {
                          lg: 20,
                          md: 20,
                          sm: 16,
                          xs: 16,
                        },

                        height: "auto",
                        margin: 0,
                        color: "#FFFFFF",
                      }} />
                    </InputAdornment>
                  }
                />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          {selected.length > 0 && filteredBlogs?.length && (
            <>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                style={{ backgroundColor: "#E50909", color: '#FFFFFF' }}
                onClick={handleDeleteBlog}
              >
                Delete
              </Button>
            </>
          )}
          {reorderedRows?.length && filteredBlogs?.length && <Button
            variant="contained"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleSaveOrder}
          >
            Save order
          </Button>}
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddBlog}
          >
            Add New Blog
          </Button>

        </Box>
      </Toolbar>

      <Paper>
        <TableContainer >
          <Table>
            <StyledTableHead >
              <TableRow >
                <StyledTableCell></StyledTableCell>

                <StyledTableCell padding="checkbox">
                  <Checkbox
                    sx={{
                      color: filteredBlogs?.length === 0 ? "#B0B0B0" : "#143F67",
                      cursor: filteredBlogs?.length === 0 && "not-allowed"
                    }}
                    checked={selected?.length > 0 && selected?.length === filteredBlogs?.length}
                    onChange={handleSelectAll}
                    disabled={filteredBlogs?.length === 0}
                    aria-disabled={filteredBlogs?.length === 0}
                  />
                </StyledTableCell>
                <StyledTableCell>Blog Title</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Created At</StyledTableCell>
                <StyledTableCell>Modified At</StyledTableCell>
                <StyledTableCell>Published At</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {/* {filteredBlogs?.length && filteredBlogs
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((blog) => ( */}
                  {filteredCurrentPageBlogs.length > 0 ? (
                    filteredCurrentPageBlogs.map((blog) => (
                  <StyledTableRow key={blog?._id} selected={selected?.includes(blog?._id)}
                    draggable
                    onDragStart={() => handleDragStart(blog._id)}
                    onDragOver={handleDragOver}
                    onDrop={() => handleDrop(blog._id)}
                    onClick={() => navigate(`/dashboard/blogs/manage-blogs?blog_id=${blog._id}`)}
                    className={`blog-row ${getRowClassName(blog._id)}`}
                  >
                    <StyledTableCell
                      style={{
                        cursor: `url('/assets/DragCursor.png'), pointer`,
                      }}
                    >
                      <Tooltip title={' Click and hold to drag'}>
                        <DragIndicatorIcon style={{ color: 'gray' }} />
                      </Tooltip>
                    </StyledTableCell>
                    <StyledTableCell padding="checkbox">
                      <Checkbox
                        sx={{ color: '#143F67' }}
                        checked={selected?.includes(blog?._id)}
                        onClick={(e) => { e.stopPropagation(); handleSelect(blog?._id) }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box display={"flex"} gap={2}>
                        <Box>
                          <div style={{ width: "100px", height: "40px" }}>
                            <img style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} loading="lazy" src={blog && blog.thumbnailUrl ? `${retrieveLogoUrl}/${blog?.thumbnailUrl}` : `${retrieveLogoUrl}/${blog?.bannerImage}`} alt="thumbnail" />
                          </div>
                        </Box>
                        <Box>
                          {blog?.blogTitle}
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{renderStatus(blog?.status)}</StyledTableCell>
                    <StyledTableCell>{blog?.created_at ? formatDate(blog?.created_at) : "-"}</StyledTableCell>
                    <StyledTableCell>{blog?.updated_at ? formatDate(blog?.updated_at) : "-"}</StyledTableCell>
                    <StyledTableCell>{blog?.published_at ? formatDate(blog?.published_at) : "-"}</StyledTableCell>
                    <StyledTableCell
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleMenuClick(event, blog)
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      {blog?._id == selectedBlog?._id &&
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem sx={{ color: 'black' }} onClick={(e) => { e.stopPropagation(); handleActionClick(blog, userInfo); }}> {(blog?.status === ("archive") ? "unarchive" : "archive").charAt(0).toUpperCase() + (blog?.status === "archive" ? "unarchive" : "archive").slice(1)}</MenuItem>
                          <MenuItem sx={{ color: 'black' }} onClick={(e) => { e.stopPropagation(); handleDeleteBlog(); handleSelect(blog?._id) }}>Delete</MenuItem>
                          {blog?.status == "published" && <MenuItem sx={{ color: 'black' }} onClick={(e) => { e.stopPropagation(); handleView() }}>View</MenuItem>}
                        </Menu>
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                   ))
                  ) : (
                    <TableRow>
                      <StyledTableCell colSpan={8} align="center">
                        No blogs found.
                      </StyledTableCell>
                    </TableRow>
                  )}
                {/* ))} */}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Stack>
          {filteredBlogs.length == 0 && <Typography sx={{
            color: "black", display: 'flex', justifyContent: 'center', paddingBottom: "20px",
            fontFamily: typography.fontFamilyRegular
          }}>{"No record Found "}</Typography>}
        </Stack> */}
        <StyledTablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          // count={filteredBlogs.length}
          count={searchText?.length>0?filteredCurrentPageBlogs?.length:filteredBlogs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <div style={{ padding: '30px' }}>
          <DialogTitle sx={{
            color: "black", textAlign: 'center',
            fontSize: '24px'
          }}>Are you sure you want to delete this {selected?.length > 1 ? "blogs" : "blog"}?</DialogTitle>
          <DialogContent sx={{
            color: "black",
            fontFamily: typography.fontFamilyRegular,
          }}>
            <Typography sx={{
              fontSize: '14px'

            }}>This blog will be permanently deleted and cannot be restored</Typography>
          </DialogContent >
          <DialogActions>
            <Button variant="outlined" onClick={() => {
              setOpenDialog(false)
              setAnchorEl(null);
            }}
              sx={{ mr: 2 }}>Cancel</Button>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              style={{ backgroundColor: "#E50909", color: '#FFFFFF' }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Modal
        open={modal.open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ color: modal.severity === 'success' ? 'green' : 'red' }}
          >
            {modal.severity === 'success' ? 'Success' : 'Error'}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }} style={{ color: 'black' }}>
            {modal.message}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleModalClose}
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>

    </Box>
  );
};

export default BlogManagement;
